import { useAuth0 } from "@auth0/auth0-react";
import * as Sentry from "@sentry/nextjs";
import mixpanel from "mixpanel-browser";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { Auth0User, Auth0UserMetadata, Profile } from "../../@types/data";
import { DEV } from "../../constants/env";
import { ProfileContext } from "../../context";
import { parseAuth0Profile, useAccessToken } from "./auth0Utils";
import { AUTH_REQUESTED_KEY, AUTH_TYPE_KEY } from "./utils";

export const useProfile = () => useContext(ProfileContext);

const initialisedMetadataObj: Auth0UserMetadata = {
  contactNumber: undefined,
  contentTypes: undefined,
  country: undefined,
  sourceLanguage: undefined,
  targetLanguage: undefined,
  preferredName: undefined,
  timezone: undefined,
  workTimePreference: undefined
};

const ProfileProvider = ({ children }: { children: React.ReactNode }) => {
  const [profile, setProfile] = useState<Profile | null>(null);
  const {
    isAuthenticated,
    isLoading: isAuth0UserLoading,
    user,
    logout
  } = useAuth0();

  // This is used to prevent actions from triggering prior auth check has happened (i.e. redirecting out to the home)
  // For auth0 there two loading states, one to get the user from auth0 and 2nd to get the id token from auth0
  // Therefore we have another loading state to await till we have the token
  const [isAuth0Loading, setIsAuth0Loading] = useState(true);

  const isMixpanelInitialisedRef = React.useRef(false);
  const [authProvider, setAuthProvider] = useState<"auth0">("auth0");
  const [isAuth0BinderTokenLoading, setIsAuth0BinderTokenLoading] =
    useState<boolean>(true);

  const [userData, setUserData] = useState<Auth0User>();

  const [, setIsAuth0MangementTokenLoading] = useState<boolean>(true);

  const hasRole = React.useMemo(
    () => Object?.values(profile?.groups || {})?.includes(true),
    [profile]
  );

  useEffect(() => {
    if (profile?.sub && process.env.MIXPANEL_ID) {
      mixpanel.init(process.env.MIXPANEL_ID, {
        debug: DEV,
        verbose: true,
        api_host: "https://mp.papercup.com",
        // @ts-ignore
        ignore_dnt: true
      });
      isMixpanelInitialisedRef.current = true;
      mixpanel.identify(profile.sub);
      mixpanel.people.set(profile);
      mixpanel.track("sign in");
    }
  }, [profile]);

  useEffect(() => {
    if (isAuthenticated) {
      if (user) {
        // Since we are now in Auth0 world, we don't need to load it
        setAuthProvider("auth0");
        // This is needed to ensure we get stuck on loading page
        sessionStorage.setItem(AUTH_TYPE_KEY, "auth0");
        setProfile(parseAuth0Profile(user));

        Sentry.setUser({ email: user?.email, username: user?.name });
      }
    }
  }, [isAuth0UserLoading, isAuthenticated, user]);

  const [binderTokenRef, refreshTokenCallback] = useAccessToken(
    {
      shouldRefreshToken: Boolean(user?.sub),
      // Use binder specifc audience
      auth0Options: {
        audience: process.env.AUTH0_PAPERCUP_SERVICES_AUDIENCE || ""
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onToken: () => {
        setIsAuth0BinderTokenLoading(false);
      }
    },
    [isAuthenticated]
  );

  const [auth0MangementTokenRef] = useAccessToken(
    {
      shouldRefreshToken: Boolean(user?.sub),
      // Use binder specifc audience
      auth0Options: {
        audience: process.env.AUTH0_AUDIENCE || ""
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onToken: () => {
        setIsAuth0MangementTokenLoading(false);
      }
    },
    [isAuthenticated]
  );

  useEffect(() => {
    getUserData();
  }, [auth0MangementTokenRef.current]);

  const getUserData = async () => {
    if (auth0MangementTokenRef.current) {
      const data: Auth0User = await (
        await fetch(
          `https://${process.env.AUTH0_DOMAIN}/api/v2/users/${user?.sub}`,
          {
            headers: {
              Authorization: `Bearer ${auth0MangementTokenRef.current}`,
              "Content-Type": "application/json"
            }
          }
        )
      ).json();
      const initialisedData = Object.assign(
        initialisedMetadataObj,
        data.user_metadata
      );
      setUserData({ ...data, user_metadata: initialisedData });
    }
  };

  const updateUserMetadata = async (user_metadata: Auth0UserMetadata) => {
    const data = await (
      await fetch(
        `https://${process.env.AUTH0_DOMAIN}/api/v2/users/${user?.sub}`,
        {
          method: "PATCH",
          body: JSON.stringify({
            user_metadata
          }),
          headers: {
            Authorization: `Bearer ${auth0MangementTokenRef.current}`,
            "Content-Type": "application/json"
          }
        }
      )
    ).json();
    const initialisedData = Object.assign(
      initialisedMetadataObj,
      data.user_metadata
    );
    setUserData({ ...data, user_metadata: initialisedData });
  };

  useEffect(() => {
    if (isAuth0UserLoading) {
      setIsAuth0Loading(true);
      return;
    }
    // If auth0 finished loading the profile
    // we want to wait for the binder access token token to get the apollo client
    if (isAuthenticated) {
      setIsAuth0Loading(isAuth0BinderTokenLoading);
    } else {
      setIsAuth0Loading(false);
    }
  }, [isAuth0UserLoading, isAuth0BinderTokenLoading, isAuthenticated]);

  return (
    <ProfileContext.Provider
      value={{
        // @ts-ignore
        profile,
        hasRole,
        // @ts-ignore
        userData,
        updateUserMetadata,
        setProfile,
        authProvider,
        isLoading: isAuth0Loading,
        isTokenLoading: isAuth0BinderTokenLoading,
        token: binderTokenRef,
        refreshTokenCallback,
        // #DEPRECATED - use isAuthenticated instead once migration is complete of all users to Auth0
        isAuthenticated: Boolean(
          isAuthenticated ? binderTokenRef.current : profile?.sub
        ),
        // @ts-ignore
        loginWithSocial: () => {
          sessionStorage.setItem(AUTH_REQUESTED_KEY, "true");
        },
        logout: () => {
          if (isMixpanelInitialisedRef.current) {
            mixpanel.reset();
            isMixpanelInitialisedRef.current = false;
          }
          setIsAuth0Loading(true); // stops additional redirects after logging out
          sessionStorage.removeItem(AUTH_TYPE_KEY);
          return logout({
            returnTo: window.location.origin
          });
        }
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

export { ProfileProvider };
