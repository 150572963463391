import "isomorphic-fetch";
import { AppProps } from "next/app";
import Head from "next/head";

import "@uppy/core/dist/style.css";
import "@uppy/file-input/dist/style.css";
import "@uppy/progress-bar/dist/style.css";
import "react-dates/lib/css/_datepicker.css";

import "../src/themes/browserOverrides.css";
import "../src/themes/customClasses.css";
import "../src/themes/effects.css";
import "../src/themes/fonts.css";
import "../src/themes/light.css";
import "../src/themes/materialIcons.css";
import "../src/themes/overrides.css";
import "../src/themes/tachyons.css";
import "../src/themes/toggle.css";

import Providers from "../src/components/Providers";

const MyApp = ({ Component, pageProps }: AppProps) => {
  return (
    <>
      <Head>
        <meta
          name={"viewport"}
          content={
            "width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no"
          }
        />
      </Head>
      <Providers>
        <Component {...pageProps} />
        <style jsx global>{`
          #__next {
            display: flex;
            flex-direction: column;
          }
        `}</style>
      </Providers>
    </>
  );
};

export default MyApp;
