import { PapercupThemeProvider } from "@papercup-ai/orizuru";
import { ErrorBoundary as SentryErrorBoundary } from "@sentry/nextjs";
import Auth0Wrapper from "../pages/Base/Auth0Wrapper";
import { ApolloProvider } from "../providers/ApolloProvider";
import { SessionProvider } from "../providers/SessionProvider";
import { ThemeProvider } from "../providers/ThemeProvider";
import { FlagsmithProviderWrapper } from "../providers/featureFlags/FlagsmithProviderWrapper";
import { ProfileProvider } from "../providers/profileProvider/ProfileProvider";

type Props = {
  children: React.ReactNode;
};

const Providers = ({ children }: Props) => {
  return (
    <SentryErrorBoundary>
      <PapercupThemeProvider resetCSS={false}>
        <Auth0Wrapper>
          <ProfileProvider>
            <FlagsmithProviderWrapper>
              <ThemeProvider>
                <ApolloProvider>
                  <SessionProvider>{children}</SessionProvider>
                </ApolloProvider>
              </ThemeProvider>
            </FlagsmithProviderWrapper>
          </ProfileProvider>
        </Auth0Wrapper>
      </PapercupThemeProvider>
    </SentryErrorBoundary>
  );
};

export default Providers;
