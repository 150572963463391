import axios from "axios";
import { useSetAtom } from "jotai";
import { LatestSession } from "../../@types/data";
import { useProfile } from "../../providers/profileProvider/ProfileProvider";
import { latestSessionAtom } from "../atoms/heartbeat.atom";
import { Heartbeat, HeartbeatResponse } from "../types/heartbeat.types";

export type GetLatestSessionResponse = {
  session: LatestSession;
};

const useHeartbeatStore = () => {
  const { token } = useProfile();

  const setLatestSession = useSetAtom(latestSessionAtom);
  const axiosClient = axios.create({
    baseURL: process.env.PUNCH_CARD_URL
  });

  // token is sent here because the client doesnt refresh the token when it expires
  const sendHeartbeat = (body: Heartbeat) => {
    return axiosClient.post<HeartbeatResponse>("heartbeat", body, {
      headers: {
        Authorization: `Bearer ${token.current}`
      }
    });
  };

  const getLastestSession = async () => {
    const res = await axiosClient.get<GetLatestSessionResponse>(
      `/latest_session`,
      {
        headers: {
          Authorization: `Bearer ${token.current}`
        }
      }
    );
    setLatestSession(res.data.session);
  };

  return {
    sendHeartbeat,
    getLastestSession
  };
};

export default useHeartbeatStore;
