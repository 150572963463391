import { Auth0Provider } from "@auth0/auth0-react";
import * as React from "react";
import { AUTH0_SCOPE } from "../../constants/env";

const Auth0Wrapper = ({ children }: { children: React.ReactNode }) => {
  if (!process.env.AUTH0_DOMAIN) {
    throw Error("AUTH0_DOMAIN is not set");
  }
  if (!process.env.AUTH0_CLIENT_ID) {
    throw Error("AUTH0_CLIENT_ID is not set");
  }
  if (!process.env.AUTH0_AUDIENCE) {
    throw Error("AUTH0_AUDIENCE is not set");
  }
  return (
    <Auth0Provider
      domain={process.env.AUTH0_DOMAIN}
      clientId={process.env.AUTH0_CLIENT_ID}
      redirectUri={
        typeof window !== "undefined"
          ? window.location.origin + "/dashboard?authType=auth0"
          : ""
      }
      audience={process.env.AUTH0_AUDIENCE}
      cacheLocation={"localstorage"}
      scope={AUTH0_SCOPE}
      useRefreshTokens
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0Wrapper;
