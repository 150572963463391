export enum MIXPANEL_CATEGORIES {
  MODAL = "modal",
  CORRECTION_OPERATION = "Correction operation",
  SEGMENT_OPERATION = "Segment operation",
  TIMELINE = "Timeline",
  STUDIO = "studio",
  AJAX = "ajax",
  POPUP = "popup",
  BULK_UPDATE = "Bulk update",
  SIDEBAR = "Sidebar",
  TIMER = "timer",
  TRANSLATION = "translation",
  AUDIO_OPERATION = "Audio operation",
  ARTEFACT = "artefact",
  SHORTCUTS = "shortcuts"
}
