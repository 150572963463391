import { GetTokenSilentlyOptions, useAuth0, User } from "@auth0/auth0-react";
import { camelCase, mapKeys, pick } from "lodash";
import React, { useEffect, useRef } from "react";
import { Profile } from "../../@types/data";
import { extractGroups, FIELDS_TO_EXTRACT } from "./utils";

export const parseAuth0Profile = (user: User): Profile => {
  // AWS TypeScript definition is not complete or wrong
  const profile = mapKeys(
    pick(user, FIELDS_TO_EXTRACT),
    (_: string, k: string) => camelCase(k)
  ) as Profile;

  profile["groups"] = extractGroups(user["https://origami.papercup.com/roles"]);
  profile["canEdit"] =
    profile.groups.superuser ||
    profile.groups.channelmanager ||
    profile.groups.admin;
  return profile;
};

export const useAccessToken = (
  {
    shouldRefreshToken,
    auth0Options,
    onToken
  }: {
    shouldRefreshToken: boolean;
    auth0Options: GetTokenSilentlyOptions;
    onToken?: (token: string) => void;
  },
  deps: any[]
): [React.MutableRefObject<string | undefined>, () => Promise<void>] => {
  const token = useRef<string | undefined>();
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();
  const requestRefreshToken = async () => {
    try {
      try {
        token.current = await getAccessTokenSilently(auth0Options);
      } catch (e) {
        console.log(e);
      }
      if (!token.current) {
        token.current = await getAccessTokenWithPopup(auth0Options);
      }
    } catch (e) {
      console.log(e.message);
    }
    if (onToken && token.current) {
      onToken(token.current);
    }
  };
  useEffect(() => {
    if (shouldRefreshToken) {
      requestRefreshToken();
    }
  }, [...deps, shouldRefreshToken]);

  return [token, requestRefreshToken];
};
