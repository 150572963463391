// Use this if you need to access colours from Styled JSX. It cannot access CSS variables defined in overrides.css
// We want to retain same naming abstraction as in our CSS
// Not using camelcase so we can easily get the needed constant by doing .replace on a classname and removing -
import { ColoursBackground, ColoursText } from "../@types/styling";

export const baseColours = {
  orange: "rgb(252, 78, 54)",
  "orange-10": "rgb(252, 78, 54, 0.1)",
  "orange-50": "rgba(252, 78, 54, 0.50)",
  red: "rgb(248, 34, 84)",
  "red-10": "rgba(248, 34, 84, 0.10)",
  "red-7": "rgba(248, 34, 84, 0.07)",
  yellow: "rgb(255, 140, 49)",
  "yellow-10": "rgba(255, 140, 49, 0.10)",
  "yellow-7": "rgba(255, 140, 49, 0.07)",
  green: "rgb(22, 206, 109)",
  "green-10": "rgba(22, 206, 109, 0.10)",
  "green-7": "rgba(22, 206, 109, 0.07)",
  "black-95": "rgba(39, 38, 41, 0.95)",
  "near-black": "rgb(46, 45, 48)",
  "near-black-20": "rgba(46, 45, 48, 0.20)",
  "near-black-80": "rgba(46, 45, 48, 0.80)",
  "dark-grey": "rgb(53, 52, 56)",
  "mid-grey": "rgb(61, 59, 64)",
  "mid-grey-90": "rgba(61, 59, 64, 0.9)",
  grey: "rgb(152, 150, 147)",
  "grey-20": "rgba(152, 150, 147, 0.20)",
  "grey-50": "rgba(152, 150, 147, 0.50)",
  "grey-10": "rgba(152, 150, 147, 0.10)",
  "grey-7": "rgba(152, 150, 147, 0.07)",
  "near-white": "rgb(248, 247, 247)",
  "near-white-10": "rgba(248, 247, 247, 0.03)",
  splash:
    "linear-gradient(116deg, #de124c 6%, rgb(252,78,54) 21%, rgb(252,78,54) 71%, #fd6934 79%, #fe7b32 84%)"
};

export const colours: {
  [K in ColoursText]: string;
} = {
  "c-primary": baseColours["near-white"],
  "c-secondary": baseColours.grey,
  "c-contrast": baseColours["near-white"],
  "c-ternary": baseColours["mid-grey"],
  "c-invert": baseColours["near-black"],
  "c-brand": baseColours.orange,
  "c-error": baseColours.red,
  "c-warning": baseColours.yellow,
  "c-success": baseColours.green
};

export const lightColours: {
  [K in ColoursText]: string;
} = {
  "c-primary": baseColours["near-white"],
  "c-secondary": baseColours.grey,
  "c-contrast": baseColours["near-white"],
  "c-ternary": baseColours["mid-grey"],
  "c-invert": baseColours["near-black"],
  "c-brand": baseColours.orange,
  "c-error": baseColours.red,
  "c-warning": baseColours.yellow,
  "c-success": baseColours.green
};

export const darkBgColours: {
  [K in ColoursBackground]: string;
} = {
  "bg-primary": baseColours["near-black"],
  "bg-primary-80": baseColours["near-black-80"],
  "bg-secondary": baseColours["dark-grey"],
  "bg-ternary": baseColours["mid-grey"],
  "bg-invert": baseColours["near-white"],
  "bg-brand": baseColours.orange,
  "bg-brand-10": baseColours["orange-10"],
  "bg-error": baseColours.red,
  "bg-warning": baseColours.yellow,
  "bg-success": baseColours.green,
  "bg-error-10": baseColours["red-10"],
  "bg-warning-10": baseColours["yellow-10"],
  "bg-success-10": baseColours["green-10"],
  "bg-default-10": baseColours["grey-10"],
  "bg-splash": baseColours["splash"]
};

export const lightBgColours: {
  [K in ColoursBackground]: string;
} = {
  "bg-primary": baseColours["near-black"],
  "bg-primary-80": baseColours["near-black-80"],
  "bg-secondary": baseColours["near-white"],
  "bg-ternary": baseColours["mid-grey"],
  "bg-invert": baseColours["near-white"],
  "bg-brand": baseColours.orange,
  "bg-brand-10": baseColours["orange-10"],
  "bg-error": baseColours.red,
  "bg-warning": baseColours.yellow,
  "bg-success": baseColours.green,
  "bg-error-10": baseColours["red-10"],
  "bg-warning-10": baseColours["yellow-10"],
  "bg-success-10": baseColours["green-10"],
  "bg-default-10": baseColours["grey-10"],
  "bg-splash": baseColours["splash"]
};
