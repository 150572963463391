/* eslint-disable react-hooks/rules-of-hooks */
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider as ApolloProviderBase,
  HttpLink,
  InMemoryCache
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import * as React from "react";
import { useEffect, useState } from "react";
import { MY_PROFILE } from "../graphql/queries/user";
import { useProfile } from "./profileProvider/ProfileProvider";

export const ApolloProvider: React.FunctionComponent<any> = ({ children }) => {
  // Init Apollo only if the user is logged in
  const {
    isAuthenticated,
    authProvider,
    isTokenLoading,
    token: auth0Token,
    setProfile,
    refreshTokenCallback,
    hasRole
  } = useProfile();
  if (!hasRole) return <>{children}</>;
  const [client, setClient] = useState<ApolloClient<any>>();
  const [loading, setLoading] = useState(true);

  // @ts-ignore
  const withAuth0Token = setContext(() => {
    // This will return cached token if it exists and has more than 60s to expire.
    // If it does not exist, it will return request Auth0 for a new one
    return refreshTokenCallback();
  });

  useEffect(() => {
    if (isAuthenticated && authProvider === "auth0" && auth0Token.current) {
      const httpLink = new HttpLink({
        uri: process.env.BINDER_URI
      });

      const authMiddleware = new ApolloLink((operation, forward) => {
        // Start recording before the request starts
        if (forward !== undefined) {
          operation.setContext({
            headers: {
              authorization: `Bearer-auth0 ${auth0Token.current}`
            }
          });
          // End the recording once we get the result
          // https://github.com/apollographql/apollo-client/issues/4017#issuecomment-615151013
          // https://www.apollographql.com/docs/link/overview/#context
          return forward(operation).map(result => {
            return result;
          });
        }
        return null;
      });

      const authHttpLink = withAuth0Token
        .concat(authMiddleware)
        .concat(httpLink);

      const c = new ApolloClient({
        link: authHttpLink,
        cache: new InMemoryCache(),
        // From Apollo Local state management docs:
        // If you want to use Apollo Client's @client support to query the cache without using local resolvers,
        // you must pass an empty object into the ApolloClient constructor resolvers option.
        resolvers: {}
      });
      setClient(c);
    }
  }, [isAuthenticated, authProvider, isTokenLoading]);

  // This will update the profile when the user with their binder user id
  useEffect(() => {
    if (client) {
      client.query({ query: MY_PROFILE }).then(result => {
        setProfile(prevProfile =>
          prevProfile
            ? {
                ...prevProfile,
                binderUserId: result.data.myProfile.id
              }
            : null
        );
        setLoading(false);
      });
    }
  }, [client, setProfile]);

  return client && !loading ? (
    <ApolloProviderBase client={client}>{children}</ApolloProviderBase>
  ) : (
    children
  );
};
