import { UserGroups } from "../../@types/data";

export const AUTH_TYPE_KEY = "AUTH_TYPE";
export const AUTH_REQUESTED_KEY = "AUTH_REQUESTED";

export const extractGroups = (groupsArr: string[]): UserGroups =>
  groupsArr.reduce((acc: any, group: string) => {
    acc[group.toLowerCase()] = true;
    return acc;
  }, {});

export const FIELDS_TO_EXTRACT = [
  "name",
  "family_name",
  "given_name",
  "picture",
  "email",
  "sub"
];
