import flagsmith from "flagsmith";
import { FlagsmithProvider } from "flagsmith/react";
import * as React from "react";
import { useProfile } from "../profileProvider/ProfileProvider";

// Initialises flagsmith in the project
export const FlagsmithProviderWrapper = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const { profile } = useProfile();
  const flagsmithApiKey = process.env.FLAGSMITH_CLIENT_API_KEY;

  if (!flagsmithApiKey || !profile?.sub) {
    // identity can only be set once.
    // Traits can only be set after user is identified.
    return <>{children}</>;
  }

  return (
    <FlagsmithProvider
      flagsmith={flagsmith}
      options={{
        environmentID: flagsmithApiKey,
        identity: profile?.sub,
        api: process.env.FLAGSMITH_API_URL,
        cacheFlags: true,
        cacheOptions: {
          ttl: 60 * 60 * 1000
        }
      }}
    >
      {/* @ts-ignore */}
      {children}
    </FlagsmithProvider>
  );
};
